import * as bootstrap from 'bootstrap'

// Старый js и css перенесенный в сборщик
import dayjs from '@/helpers/time'
import toasts from '@/helpers/toasts'

// Экспорты библиотек
window.bootstrap = bootstrap
window.dayjs = dayjs

// Экспорты легаси-функционала
window.ToastGen = toasts.ToastGen
window.ToastError = toasts.ToastError
window.ToastSuccess = toasts.ToastSuccess